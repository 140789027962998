<template>
  <div class="container">


    <div class="departments-form mt-4">
      <h4 style="text-align: center">Департаменты</h4>


      <!-- Delete Departments Modal -->
      <div class="modal fade" id="deleteDepartmentsModal" tabindex="-1"
           aria-labelledby="deleteDepartmentsModalLabel"
           aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteDepartmentsModalLabel">Удаление</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-4">
                <p>Вы точно хотите удалить это?</p>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                      @click="deleteDepartments(deleteDepartmentsId)">
                Удалить
              </button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Отменить
              </button>

            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <div class="education-module-table">
        <div class="row mt-4">

          <div class="col-md-2">
            №
          </div>
          <div class="col-md-4">
            Название на русском
          </div>
          <div class="col-md-4">
            Название группы
          </div>
          <div class="col-md-1">
            Редактировать
          </div>
          <div class="col-md-1">
            Удалить
          </div>

        </div>
        <hr>
        <div class="row" v-for="(item,index) in departments_form.departmentsInfos" :key="index">

          <div class="col-md-2">
            {{ index+1 }}
          </div>
          <div class="col-md-4">

            <input class="form-control"
                   placeholder="Название" :value="item.module"
                   @input="changeDepartmentsData(item, 'module', $event)"
                   :disabled="typeof item.edit_status=== 'undefined'" />

          </div>

          <div class="col-md-4">

            <input class="form-control"
                   placeholder="Название группы" :value="item.name"
                   @input="changeDepartmentsData(item, 'name', $event)"
                   :disabled="typeof item.edit_status=== 'undefined'" />

          </div>
          <div class="col-md-1">
            <button v-if="typeof item.edit_status=== 'undefined'" type="button" class="btn btn-warning"
                    @click="editDepartments(index)">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>

            <button v-if="item.edit_status == 1" type="button" class="btn btn-success"
                    @click="updateDepartments(item.id, index)">
              <i class="fas fa-check" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                    data-bs-target="#deleteDepartmentsModal"
                    @click="openDeleteDepartmentsModal(item.id)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
          </div>

          <hr>

        </div>
      </div>


      <div class="form-row" v-for="(newDepartmentsInfo, index) in departments_form.newDepartmentsInfos"
           :key="index">
        <div class="education-field-remove-item__button d-flex flex-row-reverse">
          <i class="fa fa-times" @click="deleteRowDepartmentsInfo(index)"></i>
        </div>


        <div class="form-group row mt-4">
          <label for="education_module" class="col-md-3 col-form-label">Название</label>
          <div class="col-md-9">
            <input id="education_module" type="text" class="form-control"
                   @input="changeNewDepartmentsData(newDepartmentsInfo, 'module', $event)">
          </div>

        </div>
        <div class="form-group row mt-4">
          <label for="education_module_name" class="col-md-3 col-form-label">Название группы</label>
          <div class="col-md-9">
            <input id="education_module_name" type="text" class="form-control"
                   @input="changeNewDepartmentsData(newDepartmentsInfo, 'name', $event)">
          </div>

        </div>
        <div class="form-group row mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-pill text-white btn-block btn-primary" v-on:click="submitDepartments">
              Сохранить
            </button>
          </div>
        </div>


        <hr>

      </div>
      <div class="form-group">
        <button @click="addRowDepartmentsInfo" type="button" class="btn btn-secondary"><i
            class="fas fa-plus-circle"></i>
          Добавить
        </button>
      </div>
    </div>


  </div>
</template>

<script>

import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "DepartmentsForm",


  data() {
    return {
      deleteDepartmentsId: ''
    }
  },
  computed: {
    ...mapState('departments', ['departments_form']),
  },

  methods: {
    ...mapActions('departments', ['POST_DEPARTMENTS_DATA', 'PUT_DEPARTMENTS_DATA', 'GET_DEPARTMENTS_DATA', 'DELETE_DEPARTMENTS_DATA']),
    ...mapMutations('departments', ['UPDATE_DEPARTMENTS_DATA', 'UPDATE_NEW_DEPARTMENTS_DATA',
      'DELETE_ROW_DEPARTMENTS_INFO', 'ADD_ROW_DEPARTMENTS_INFO', 'CHANGE_EDIT_STATUS_DEPARTMENTS_INFO']),


    async deleteDepartments(id) {
      await this.DELETE_DEPARTMENTS_DATA(id);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_DEPARTMENTS_DATA();
    },
    async editDepartments(index) {
      this.CHANGE_EDIT_STATUS_DEPARTMENTS_INFO(index)

    },
    async openDeleteDepartmentsModal(id) {
      this.deleteDepartmentsId = id;
    },
    async updateDepartments(id, index) {
      delete this.departments_form.departmentsInfos[index].edit_status;
      await this.PUT_DEPARTMENTS_DATA(id)
    },

    deleteRowDepartmentsInfo(index) {
      this.DELETE_ROW_DEPARTMENTS_INFO(index);
    },
    addRowDepartmentsInfo() {
      this.ADD_ROW_DEPARTMENTS_INFO();
    },
    submitDepartments() {

      this.POST_DEPARTMENTS_DATA().then(res => {
        if (res.success == true) {
          this.GET_DEPARTMENTS_DATA();
          this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
        } else {
          let errorText = '';
          for (let err of res.errors[0]) {
            errorText += err.message + '\n'
          }
          this.$error({title: 'Добавление', text: errorText})
        }
      })
    },
    changeDepartmentsData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_DEPARTMENTS_DATA({item, property, value})
    },
    changeNewDepartmentsData(item, property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_NEW_DEPARTMENTS_DATA({item, property, value})
    },
  },
  created() {
    this.GET_DEPARTMENTS_DATA();
  }
}
</script>

<style scoped>

</style>